<template>
    <div class="patientHome">
        <div class="myHomeTop">
            <van-image v-if="!equipments.length && isMember" width="100%" :src="myHomeTop" @click="toUserPage" />
            <van-image
                v-if="!equipments.length && !isMember"
                width="100%"
                :src="myHomeTopAdd"
                @click="toEditUserPage"
            />
        </div>
        <div v-if="equipments.length" class="instrumentCard m10 mt0">
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">我的治疗仪</div>
                <div class="cardTileRight cellFlex-1">
                    <van-button
                        round
                        size="mini"
                        plain
                        color="#4AB8AB"
                        icon="play"
                        type="info"
                        @click="showEquipmentsPopup = true"
                    >
                        切换治疗仪
                    </van-button>
                </div>
            </div>
            <div class="cardBody">
                <div class="cell">
                    <div class="cardBodyLeft">ID</div>
                    <div class="cardBodyRight cellFlex-1">{{ curSn }}</div>
                </div>
            </div>
        </div>
        <div class="myHomePopularize">
            <div v-if="equipments.length">
                <div class="userInfo">
                    <van-cell-group inset>
                        <van-cell class="bgNo" center>
                            <template #icon>
                                <van-image
                                    :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                    width="35"
                                />
                            </template>
                            <template #title>
                                <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                <van-icon
                                    v-if="curSnUser.patientSex"
                                    :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                    class="sex-icon"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                    <van-row class="course">
                        <van-col span="9">
                            <div class="courseTitle">预计下次验配</div>
                            <div class="courseInfo">2022-01-18</div>
                        </van-col>
                        <van-col span="6">
                            <div class="courseTitle">可用疗程验配</div>
                            <div class="courseInfo">{{ curSnUser.treatmentCnt }}个</div>
                        </van-col>
                        <van-col span="9">
                            <div class="courseTitle">可用疗程期</div>
                            <div class="courseInfo">2022-03-31</div>
                        </van-col>
                    </van-row>
                </div>
                <van-row class="prompt">
                    <van-col span="24">
                        <div
                            class="promptTitle plr10"
                            :style="{
                                background: curSnUser.treatmentCnt > 1 ? '#1bc6a3' : '#ec3e36',
                            }"
                        >
                            {{
                                curSnUser.treatmentCnt > 1
                                    ? '* 您的治疗疗程充足，请坚持治疗，早日康复！'
                                    : '* 您的治疗疗程不足，请尽快续费，坚持治疗早日康复！'
                            }}
                        </div>
                    </van-col>
                </van-row>
            </div>
            <div class="popularizeTitle modTitle">
                <div class="modIcon">
                    <van-image width="15" :src="require('@/assets/images/crown.png')"></van-image>
                </div>
                <div class="modText">会员权益</div>
                <div class="modSubheading">免费服务 · 专家诊疗 · 康复沙龙 · 专享优惠</div>
            </div>
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <van-image width="100%" :src="image" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <van-popup v-model="showEquipmentsPopup" position="bottom">
            <van-picker
                show-toolbar
                :columns="equipmentColumns"
                @confirm="selectCurEquipment"
                @cancel="showEquipmentsPopup = false"
            />
        </van-popup>
    </div>
</template>
<script>
import { toast } from '@/util';
import { getMyEquipments, getUser } from '@/services/user';

export default {
    data() {
        return {
            equipments: [],
            showEquipmentsPopup: false,
            equipmentColumns: [],
            curSn: '',
            curSnUser: {},
            isMember: true,
            myHomeTop: require('@/assets/images/my-home-top.png'),
            myHomeTopAdd: require('@/assets/images/my-home-top-add.png'),
            images: [
                require('@/assets/images/my-home-popularize.png'),
                require('@/assets/images/my-home-popularize.png'),
            ],
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
        };
    },
    created() {
        this.getUser();
        this.getMyEquipments();
    },
    methods: {
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            if (!data.name) {
                this.isMember = false;
            }
        },

        async getMyEquipments() {
            const { code, message, data } = await getMyEquipments();
            if (!code) {
                return toast(message);
            }
            this.equipments = data;
            this.equipmentColumns = this.equipments.map(e => `${e.patientName} ${e.sn}`);
            if (this.equipments.length) {
                this.curSn = localStorage.getItem('curSn');
                if (!this.curSn || !this.equipments.map(e => e.sn).includes(this.curSn)) {
                    this.curSn = this.equipments[0].sn;
                    localStorage.setItem('curSn', this.curSn);
                    this.curSnUser = this.equipments.filter(e => e.sn === this.curSn)[0];
                    localStorage.setItem('curSnUser', JSON.stringify(this.curSnUser));
                }
                this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
            }
        },

        selectCurEquipment(val) {
            this.curSnUser = this.equipments.filter(e => `${e.patientName} ${e.sn}` === val)[0];
            this.curSn = this.curSnUser.sn;
            localStorage.setItem('curSn', this.curSn);
            localStorage.setItem('curSnUser', JSON.stringify(this.curSnUser));
            this.showEquipmentsPopup = false;
        },

        async toEditUserPage() {
            await this.$router.push({ path: '/patient/my/editUser' });
        },
        async toUserPage() {
            await this.$router.push({ path: '/patient/reservation' });
        }
    },
};
</script>
<style scoped>
.patientHome {
    padding-bottom: 50px;
}

.myHomeTop {
    padding: 10px;
}

.myHomePopularize {
    height: 100%;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0 10px 10px;
}

.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #bebebe;
}

.erNing::before {
    content: 'ERNING';
    display: block;
    position: absolute;
    color: #acacac;
    font-size: 18px;
    top: 2px;
    left: 21px;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: center;
}

.noInstrument {
    font-size: 12px;
    padding: 15px 16px;
    color: #8d8d8d;
    font-weight: 600;
}

.userInfo {
    padding: 10px;
}

.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}

.userName {
    font-size: 16px;
    color: #282a2d;
}

.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}

.course {
    text-align: center;
    line-height: 24px;
    color: #282a2d;
    padding: 10px 0;
}

.course .van-col {
    border-right: 1px solid #707070;
}

.course .van-col:last-child {
    border-right: none;
}

.courseTitle {
    font-size: 12px;
}

.courseInfo {
    font-size: 16px;
    font-weight: 600;
}

.promptTitle {
    background: #ec3e36;
    font-size: 12px;
    color: #fff;
    line-height: 35px;
}
</style>
